import { generateDateArray } from 'kadro-helpers/lib/helpers';

import { conn } from '@/actions';
import { createAttendance } from '@/actions/attendances';
import { getLabels } from '@/actions/labels';
import { getLoanEmployeesProposalsAssignments } from '@/actions/loanEmployeesProposals';
import { showModal } from '@/actions/uiState';
import { getPayrollColumns } from '@/components/newPayrollViews/newPayroll/PayrollTable/PayrollTable.helpers';
import * as AT from '@/constants/ActionTypes';
import browserHistory from '@/constants/browserHistory';
import { ACCEPT_OVERTIME_MODAL, ADD_SHIFT_MODAL } from '@/constants/modalTypes';
import { PAYROLL_MODES } from '@/constants/payrollSettings';
import { LOAN_EMPLOYEES_ENABLE, OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE } from '@/constants/Permissions';
import { getOpenShiftsAssignmentsAction } from '@/redux-store/openShiftsAssignments';
import { computeTimestampsForNewAttendanceHours } from '@/utils/attendanceHelpers';
import { uuid4 } from '@/utils/baseHelpers';
import { getFromToFromDateStore } from '@/utils/dateHelper';
import { handleAddShiftFailureErrorMessage, shiftErrorCodesToMessages } from '@/utils/shiftHelpers';

import { messages } from './payroll.messages';

const getPayrollDataSuccessfully = (data, options, from, to, status, fetchRequestId) => ({
  type: AT.PAYROLL_GET_DATA_SUCCESS,
  payload: { data, options, from, to, status, fetchRequestId },
});

const getPayrollDataError = fetchRequestId => (dispatch, getState, intl) => {
  dispatch({
    type: AT.PAYROLL_GET_DATA_ERROR,
    payload: { fetchRequestId },
    notification: {
      title: intl.formatMessage(messages.getPaymentDataError),
      type: 'error',
    },
  });
};

const getPayrollData =
  (employeeId, locationsIds, from, to, options, requestType = 'blocking', status) =>
  async dispatch => {
    const fetchRequestId = uuid4();
    dispatch({
      type: AT.START_FETCHING_PAYROLL,
      payload: { fetchRequestId },
    });
    try {
      const result = await conn.getNewPayroll(
        employeeId,
        locationsIds,
        from,
        to,
        status,
        options?.mergeWithOldData ? 'standard' : requestType,
      );
      dispatch(getPayrollDataSuccessfully(result.data, options, from, to, status, fetchRequestId));
    } catch (err) {
      dispatch(getPayrollDataError(fetchRequestId));
    }
  };

export const getPayrollViewData = (fromParam, toParam, options, requestType, status) => async (dispatch, getState) => {
  const { singleEmployeeFilter, multipleLocationFilter, mainDateStore, userPermissions } = getState().reducer;
  const [from, to] = fromParam && toParam ? [fromParam, toParam] : getFromToFromDateStore(mainDateStore);
  const isLoanEmployeesEnabled = userPermissions.permissions.includes(LOAN_EMPLOYEES_ENABLE);
  const isOpenShiftsForLocationGroupsEnabled = userPermissions.permissions.includes(
    OPEN_SHIFTS_FOR_LOCATION_GROUPS_ENABLE,
  );
  if (isLoanEmployeesEnabled) {
    dispatch(getLoanEmployeesProposalsAssignments(from, to, multipleLocationFilter));
  }
  if (isOpenShiftsForLocationGroupsEnabled) {
    dispatch(getOpenShiftsAssignmentsAction(from, to, multipleLocationFilter));
  }
  dispatch(getPayrollData(singleEmployeeFilter.id, multipleLocationFilter, from, to, options, requestType, status));
  dispatch(getLabels());
};

export const changePayrollColumns = selected => (dispatch, getState) => {
  const {
    userPermissions,
    payrollSettings: {
      payoutSetting: { type: payoutSettingType },
    },
    currentCompany: { settings: companySettings },
  } = getState().reducer;
  const payrollTableColumns = getPayrollColumns(userPermissions, payoutSettingType, companySettings);
  const selectedColumns = payrollTableColumns.filter(
    option => option.cannotBeHidden || selected.find(sel => sel.id === option.id),
  );
  dispatch({
    type: AT.PAYROLL_CHANGE_VISIBLE_COLUMNS,
    payload: selectedColumns,
  });
};

const editShiftForPayrollError = err => (dispatch, getState, intl) => {
  dispatch({
    type: AT.EDIT_SHIFT_FOR_PAYROLL_ERROR,
    notification: {
      title: intl.formatMessage(
        err.errorCode ? shiftErrorCodesToMessages[err.errorCode] : messages.editShiftForPayrollError,
      ),
      type: 'error',
    },
  });
};

const editShiftForPayrollSuccess = (intl, shift) => ({
  type: AT.EDIT_SHIFT_FOR_PAYROLL_SUCCESS,
  notification: {
    title: intl.formatMessage(messages.editShiftForPayrollSuccess),
    type: 'success',
  },
  payload: {
    shift,
  },
});

const editShiftForPayroll = shift => async (dispatch, getState, intl) => {
  try {
    await conn.changeShift(shift, 'regular');
    dispatch(editShiftForPayrollSuccess(intl, shift));
  } catch (err) {
    if (err.response.data.errorCode === 'employeeIsBusy') {
      throw new Error(err.response.data.errorCode);
    } else {
      dispatch(editShiftForPayrollError(err.response.data));
    }
  }
};

const editAttendanceForPayrollError = intl => ({
  type: AT.EDIT_ATTENDANCE_FOR_PAYROLL_ERROR,
  notification: {
    title: intl.formatMessage(messages.editAttendanceForPayrollError),
    type: 'error',
  },
});

const editAttendanceForPayrollSuccess = (intl, attendance) => ({
  type: AT.EDIT_ATTENDANCE_FOR_PAYROLL_SUCCESS,
  notification: {
    title: intl.formatMessage(messages.editAttendanceForPayrollSuccess),
    type: 'success',
  },
  payload: {
    attendance,
  },
});

const editAttendanceForPayroll = attendance => async (dispatch, getState, intl) => {
  try {
    const { start_timestamp: startTimestamp, end_timestamp: endTimestamp } = computeTimestampsForNewAttendanceHours(
      attendance.hours,
      attendance,
    );
    await conn.changeAttendance(attendance.id, startTimestamp, endTimestamp);
    dispatch(editAttendanceForPayrollSuccess(intl, [{ attendance }]));
  } catch (err) {
    dispatch(editAttendanceForPayrollError(intl));
  }
};

export const editShiftAndAttendanceNewPayroll =
  (shift, attendance, originalShift, originalAttendance) => async dispatch => {
    const promises = [];

    const isShiftChanged =
      originalShift.working_hours !== shift.working_hours || originalShift.job_title?.id !== shift.job_title?.id;
    if (isShiftChanged) promises.push(dispatch(editShiftForPayroll(shift)));

    const isAttendanceChanged = originalAttendance.hours !== attendance.hours;
    if (isAttendanceChanged) promises.push(dispatch(editAttendanceForPayroll(attendance)));

    if (!promises.length) return;

    await Promise.all(promises);
  };

const getShiftOrAttendanceForPayrollError = intl => ({
  type: AT.PAYROLL_GET_SHIFT_OR_ATTENDANCE_ERROR,
  notification: {
    title: intl.formatMessage(messages.getPayrollShiftOrAttendanceError),
    type: 'error',
  },
});

export const getShiftOrAttendanceForPayroll = (id, isShift) => async (dispatch, getState, intl) => {
  try {
    const res = await conn.getShiftOrAttendanceForPayroll(id, isShift);
    if (!res?.data?.shift?.id && !res?.data?.attendance?.id) throw new Error('No shift and attendance');
    return res.data;
  } catch (err) {
    dispatch(getShiftOrAttendanceForPayrollError(intl));
    throw new Error(err);
  }
};

export const showAcceptOvertimeModalForPayroll = (attendanceId, shiftId) => async (dispatch, getState) => {
  const { attendance, shift } = await dispatch(getShiftOrAttendanceForPayroll(attendanceId, shiftId));
  const { singleEmployeeFilter } = getState().reducer;
  const { id, first_name: firstName, last_name: lastName } = singleEmployeeFilter;
  dispatch(
    showModal(ACCEPT_OVERTIME_MODAL, {
      selectedEmployeesAttendanceOvertimeData: [
        {
          attendance,
          shift,
          employee: { id, first_name: firstName, last_name: lastName },
          hasEarlyIn: attendance ? !shift || attendance.start_timestamp < shift.start_timestamp : false,
          hasLateOut: attendance ? !shift || attendance.end_timestamp > shift.end_timestamp : false,
        },
      ],
    }),
  );
};

const deleteShiftForPayrollError = intl => ({
  type: AT.DELETE_SHIFT_FOR_PAYROLL_ERROR,
  notification: {
    title: intl.formatMessage(messages.deleteShiftForPayrollError),
    type: 'error',
  },
});

const deleteShiftForPayrollSuccess = (intl, shift) => ({
  type: AT.DELETE_SHIFT_FOR_PAYROLL_SUCCESS,
  notification: {
    title: intl.formatMessage(messages.deleteShiftForPayrollSuccess),
    type: 'success',
  },
  payload: {
    shift,
  },
});

export const deleteShiftForPayroll = shift => async (dispatch, getState, intl) => {
  const shiftId = shift.id;
  try {
    await conn.deleteShift(shiftId);
    dispatch(deleteShiftForPayrollSuccess(intl, shift));
  } catch (err) {
    dispatch(deleteShiftForPayrollError(intl));
  }
};

const addShiftForPayrollError = (intl, notification) => ({
  type: AT.ADD_SHIFT_FOR_PAYROLL_ERROR,
  notification: notification
    ? {
        ...notification,
        type: 'error',
      }
    : {
        title: intl.formatMessage(messages.addShiftForPayrollError),
        type: 'error',
      },
});

const addShiftForPayrollSuccess = (intl, shift) => ({
  type: AT.ADD_SHIFT_FOR_PAYROLL_SUCCESS,
  notification: {
    title: intl.formatMessage(messages.addShiftForPayrollSuccess),
    description: intl.formatMessage(messages.addShiftForPayrollSuccessDescription),
    type: 'success',
  },
  payload: {
    shift,
  },
});

export const addShiftForPayroll = (shift, addAttendanceForShift) => async (dispatch, getState, intl) => {
  try {
    const { data } = await conn.addShift(shift);
    dispatch(addShiftForPayrollSuccess(intl, shift));
    if (addAttendanceForShift && data) {
      const { employee, start_timestamp: startTimestamp, end_timestamp: endTimestamp, location } = data;
      dispatch(createAttendance(employee.id, startTimestamp, endTimestamp, location.id));
    }
  } catch (err) {
    if (
      err.response.data.errorCode === 'BUDGET_TARGETS_HOURS_LIMIT_EXCEEDED' ||
      err.response.data.errorCode === 'BUDGET_TARGETS_MONEY_LIMIT_EXCEEDED' ||
      err.response.data.errorCode === 'employeeIsBusy'
    ) {
      throw new Error(err.response.data.errorCode);
    } else {
      const notification = handleAddShiftFailureErrorMessage(err, intl);
      dispatch(addShiftForPayrollError(intl, notification));
    }
  }
};

export const showModalToAddShiftForExistingAttendance = attendance => (dispatch, getState) => {
  const { userEmployees } = getState().reducer;
  const employee = userEmployees.find(({ id }) => id === attendance.employee_id);

  dispatch(
    showModal(ADD_SHIFT_MODAL, {
      employee,
      date: attendance.date,
      location: attendance.location || null,
      defaultHours: attendance.hours,
      preApproved: true,
      hideRepeatShift: true,
      customAddShift: shift => dispatch(addShiftForPayroll(shift)),
    }),
  );
};

export const redirectToPayroll = employeeId => (dispatch, getState) => {
  const { userEmployees, payrollLoanedEmployees } = getState().reducer;
  const relevantEmployees = [...userEmployees, ...payrollLoanedEmployees.data];
  const employee = relevantEmployees.find(e => e.id === employeeId);
  if (employee) {
    dispatch({
      type: AT.CHANGE_SINGLE_EMPLOYEE_FILTER,
      payload: { ...employee, name: `${employee.first_name} ${employee.last_name}` },
    });
  }
  browserHistory.push('/newPayroll');
  dispatch(getPayrollViewData());
};

export const changePayrollMode = mode => (dispatch, getState) => {
  const { payroll } = getState().reducer;
  if (mode === PAYROLL_MODES.readonly && payroll.isLoadButtonEnabled) {
    dispatch(getPayrollViewData());
  }
  dispatch({ type: AT.PAYROLL_CHANGE_MODE, payload: mode });
};

export const changePayrollDaysStatus = (employeeId, dates, newStatus) => async (dispatch, _, intl) => {
  try {
    await conn.changePayrollDaysStatus([employeeId], dates, newStatus);
    dispatch({
      type: AT.CHANGE_PAYROLL_DAYS_STATUS_SUCCESS,
      payload: {
        employeeId,
        dates,
        newStatus,
      },
      notification: {
        type: 'success',
        title: intl.formatMessage(messages.changePayrollDaysStatusSuccessTitle),
        description: intl.formatMessage(messages.changePayrollDaysStatusSuccessDescription),
      },
    });
  } catch (error) {
    dispatch({
      type: AT.CHANGE_PAYROLL_DAYS_STATUS_FAILURE,
      notification: {
        type: 'error',
        title: intl.formatMessage(messages.changePayrollDaysStatusFailureTitle),
        description: intl.formatMessage(messages.changePayrollDaysStatusFailureDescription),
      },
    });
  }
};

export const changePayrollDaysStatusForMultipleEmployees =
  (employeeIds, newStatus) => async (dispatch, getState, intl) => {
    const { start, end } = getState().reducer.mainDateStore.customDate;
    const dates = generateDateArray(start, end);
    try {
      await conn.changePayrollDaysStatus(employeeIds, dates, newStatus);
      dispatch({
        type: AT.CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_SUCCESS,
        payload: {
          employeeIds,
          dates,
          newStatus,
        },
        notification: {
          type: 'success',
          title: intl.formatMessage(messages.changePayrollDaysStatusForMultipleEmployeesSuccessTitle),
          description: intl.formatMessage(messages.changePayrollDaysStatusForMultipleEmployeesSuccessDescription),
        },
      });
    } catch (error) {
      dispatch({
        type: AT.CHANGE_PAYROLL_DAYS_STATUS_FOR_MULTIPLE_EMPLOYEES_FAILURE,
        notification: {
          type: 'error',
          title: intl.formatMessage(messages.changePayrollDaysStatusFailureTitle),
          description: intl.formatMessage(messages.changePayrollDaysStatusFailureDescription),
        },
      });
    }
  };

export const choosePayrollSummaryValues = newItems => ({
  type: AT.CHOOSE_PAYROLL_SUMMARY_VALUES,
  payload: { newItems },
});
